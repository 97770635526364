@charset "UTF-8";
/*
 *   File : global.scss
 *   Author : https://evoqins.com
 *   Description : SCSS file
 *   Integrations : NA
 *   Version : 1.0.0
*/

// @import "../Style/style.scss";
@import "../Styles/font.scss";
@import "../Styles/responsive.scss";
@import "../Styles/color.module.scss";

/* ===================================
     Reset
====================================== */

body {
    background-color: $ghost_white;
}

body::-webkit-scrollbar {
    display: none !important;
}

.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.body input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

body {
    overflow-x: hidden !important;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.e-body {
    // line-height: 30px !important;
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
    font-size: 1rem;
    // background-color: #ecedfd;

    ul,
    ol,
    dl {
        list-style-position: outside;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    button,
    button:focus,
    button:hover {
        outline: none;
    }

    b,
    strong {
        font-weight: 600;
    }
}

.outline-none {
    outline: none;
    &:focus {
        outline: none;
    }
}

.word-wrap {
    word-wrap: break-word;
}

.no-transition {
    transition: all 0s ease-in-out !important;
    -moz-transition: all 0s ease-in-out !important;
    -webkit-transition: all 0s ease-in-out !important;
    -ms-transition: all 0s ease-in-out !important;
    -o-transition: all 0s ease-in-out !important;
}

.transition {
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.transition-02s {
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.e-body::-webkit-scrollbar {
    display: none !important;
}

// Montserrat
.e-montserrat-regular {
    font-family: "Montserrat-Regular";
}

.e-montserrat-medium {
    font-family: "Montserrat-Medium";
}

.e-montserrat-semi-bold {
    font-family: "Montserrat-SemiBold";
}

.e-montserrat-bold {
    font-family: "Montserrat-Bold";
}

.word-break {
    word-break: break-word;
}

/*===============================
// COLOR
====================================== */
.color-bright-periwinkle {
    color: $bright-periwinkle;
}
.color-white {
    color: $white;
}

.color-off-white {
    color: $off_white;
}

.color-charleston-green {
    color: $charleston_green;
}

.color-black {
    color: $black;
}

.color-rebecca-purple {
    color: $rebecca_purple;
}

.color-dark-charcoal {
    color: $dark_charcoal;
}

.color-jett-black {
    color: $jett_black;
}

.color-gray {
    color: $gray;
}

.color-dark-gray {
    color: $dark_gray;
}

.color-emerald-green {
    color: $emerald_green;
}

.color-gunmetal-gray {
    color: $gunmetal_gray;
}

.color-deep-carmine-pink {
    color: $deep_carmine_pink;
}

.color-black-olive {
    color: $black_olive;
}

.color-dark-jungle-green {
    color: $dark_jungle_green;
}

.color-silver-gray {
    color: $silver_gray;
}

.color-dark-grayish-blue {
    color: $dark-grayish-blue;
}

.color-red {
    color: $red;
}

.color-medium-gray {
    color: $medium_gray;
}

//
.top-30per {
    top: 30%;
}

.top--8px {
    top: -8px;
}

.text-transform-lower {
    text-transform: lowercase;
}

.text-decoration-hover-underline:hover {
    text-decoration: underline;
}

/* ================================
            truncate
=================================== */

.truncate-first-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

/*===============================
// FONT SIZE
====================================== */
// Define a mixin to generate font sizes
@mixin font-size($size) {
    font-size: $size * 1rem;
}

// Define a mixin to generate line height
@mixin line-height($line-height) {
    line-height: $line-height * 1rem;
}

// Define a loop to generate classes for font sizes starting from 8 to 52 (even numbers)
// use like e-font-12
// e-line-height-12
@for $i from 6 through 54 {
    // Only generate classes for even numbers
    @if $i % 2==0 {
        .e-font-#{$i} {
            @include font-size($i / 16); // Divide by 10 to get the rem value
        }

        .e-line-height-#{$i} {
            @include line-height($i / 16); // Divide by 10 to get the rem value
        }
    }
}

/* ================================
            Common

            // padding, border-radius, margin, gap----
// use padding-32px-all
=================================== */

$start-value: 0;
$end-value: 500;
$increment: 2;

@for $i from $start-value through $end-value {
    $conversion-value: $i / 16;

    // border-radius
    .border-radius-#{$i}px {
        border-radius: $conversion-value * 1rem;
    }

    .border-radius-tlr-#{$i}px {
        border-top-left-radius: $conversion-value * 1rem;
        border-top-right-radius: $conversion-value * 1rem;
    }

    .border-radius-blr-#{$i}px {
        border-bottom-left-radius: $conversion-value * 1rem;
        border-bottom-right-radius: $conversion-value * 1rem;
    }

    .border-radius-trbr-#{$i}px {
        border-top-right-radius: $conversion-value * 1rem;
        border-bottom-right-radius: $conversion-value * 1rem;
    }
    .border-radius-tl-#{$i}px {
        border-top-left-radius: $conversion-value * 1rem;
    }

    .border-radius-tr-#{$i}px {
        border-top-right-radius: $conversion-value * 1rem;
    }

    .border-radius-bl-#{$i}px {
        border-bottom-left-radius: $conversion-value * 1rem;
    }

    .border-radius-br-#{$i}px {
        border-bottom-right-radius: $conversion-value * 1rem;
    }

    // padding-all
    .padding-#{$i}px-all {
        padding: $conversion-value * 1rem;
    }

    // padding-top
    .padding-#{$i}px-top {
        padding-top: $conversion-value * 1rem;
    }

    // padding-bottom
    .padding-#{$i}px-bottom {
        padding-bottom: $conversion-value * 1rem;
    }

    // padding-left
    .padding-#{$i}px-left {
        padding-left: $conversion-value * 1rem;
    }

    // padding-right
    .padding-#{$i}px-right {
        padding-right: $conversion-value * 1rem;
    }

    // padding top and bottom
    .padding-#{$i}px-tb {
        padding-top: $conversion-value * 1rem;
        padding-bottom: $conversion-value * 1rem;
    }

    // padding left and right
    .padding-#{$i}px-lr {
        padding-left: $conversion-value * 1rem;
        padding-right: $conversion-value * 1rem;
    }

    // margin-all
    .margin-#{$i}px-all {
        margin: $conversion-value * 1rem;
    }

    // margin-top
    .margin-#{$i}px-top {
        margin-top: $conversion-value * 1rem;
    }

    // margin-bottom
    .margin-#{$i}px-bottom {
        margin-bottom: $conversion-value * 1rem;
    }

    // margin-left
    .margin-#{$i}px-left {
        margin-left: $conversion-value * 1rem;
    }

    // margin-right
    .margin-#{$i}px-right {
        margin-right: $conversion-value * 1rem;
    }

    // margin top and bottom
    .margin-#{$i}px-tb {
        margin-top: $conversion-value * 1rem;
        margin-bottom: $conversion-value * 1rem;
    }

    // margin left and right
    .margin-#{$i}px-lr {
        margin-left: $conversion-value * 1rem;
        margin-right: $conversion-value * 1rem;
    }

    // gap
    .gap-#{$i}px {
        gap: $conversion-value * 1rem;
    }

    .row-gap-#{$i}px {
        row-gap: $conversion-value * 1rem;
    }

    .top-#{$i}px {
        top: $conversion-value * 1rem;
    }

    .right-#{$i}px {
        right: $conversion-value * 1rem;
    }

    .left-#{$i}px {
        left: $conversion-value * 1rem;
    }

    .bottom-#{$i}px {
        bottom: $conversion-value * 1rem;
    }

    .bottom--#{$i}px {
        bottom: -$conversion-value * 1rem;
    }

    .w-#{$i}px {
        width: $conversion-value * 1rem;
    }

    .h-#{$i}px {
        height: $conversion-value * 1rem;
    }
}

// z index
// Define the maximum z-index value
$max-z-index: 99;

// Generate z-index classes dynamically using SCSS loop
@for $i from 1 through $max-z-index {
    .z-index-#{$i} {
        z-index: $i;
    }

    .border-#{$i}px {
        border: #{$i}px solid;
    }

    .border-bottom-#{$i}px {
        border-bottom: #{$i}px solid;
    }

    .border-top-#{$i}px {
        border-top: #{$i}px solid;
    }

    .border-right-#{$i}px {
        border-right: #{$i}px solid;
    }
}

.border-bottom-point5px {
    border-bottom: solid 0.5px;
}

.border-top-point5px {
    border-top: solid 0.5px;
}

/* ===================================
     BORDER COLOR
====================================== */

.border-color-gray {
    border-color: $gray !important;
}

.border-color-rebecca-purple {
    border-color: $rebecca_purple;
}

.border-rebecca-purple {
    border: 1px solid $rebecca_purple !important;
}

.border-color-mint-gray {
    border-color: $mint_gray !important;
}

.border-color-pale-gray {
    border-color: $pale_gray !important;
}

.border-light-gray {
    border-color: $light_gray !important;
}

.border-lilac-frost {
    border-color: $lilac_frost;
}

.border-color-stroke-gray {
    border-color: $stroke_gray;
}

.border-none {
    border: none;
}

.border-point5px {
    border: 0.5px solid;
}

/*===============================
        BACKGROUND COLOR 
================================= */

.e-bg-transparent {
    background: transparent !important;
}

.e-bg-black {
    background: $black;
}

.e-bg-white {
    background: $white;
}

.e-bg-ghost-white {
    background: $ghost_white;
}

.e-bg-light-lavender {
    background: $light_lavender;
}

.e-bg-crimpson-red {
    background: $crimpson_red;
}

.e-bg-rebecca-purple {
    background: $rebecca_purple;
}

.e-bg-footer {
    background: #844cb033;
}

.e-bg-light-gray {
    background: $white_smoke;
}

.e-bg-lavender-mist {
    background: $lavender_mist;
}

.e-bg-lavender-gray {
    background-color: $lavender_gray;
}

.e-bg-pale-lilac {
    background-color: $pale_lilac;
}

.e-bg-pale-lavender {
    background-color: $pale_lavender;
}

.e-bg-golden-yellow {
    background-color: $golden_yellow !important;
}

.e-bg-magnolia {
    background-color: $magnolia;
}

.e-bg-ethereal-lavender {
    background-color: $ethereal_lavender;
}

.e-bg-lavender-gray {
    background-color: $lavender_gray;
}

.e-bg-lavender-white {
    background-color: $lavender_white;
}

.e-bg-stroke-gray {
    background: $stroke_gray;
}

.e-bg-thistle {
    background-color: $thistle;
}

.e-bg-white-lilac {
    background-color: $white_lilac;
}

.e-bg-pastel-lavender {
    background-color: $pastel_lavender;
}

.e-bg-snowy-lavender {
    background-color: $snowy_lavender;
}

.e-bg-light-purple {
    background-color: $light_purple;
}

.e-bg-pale-purple {
    background-color: $pale_purple;
}

.e-bg-medium-gray {
    background-color: $medium_gray;
}
/* border-radius */
.border-radius-100 {
    border-radius: 100%;
}

.no-border-radius {
    border-radius: 0 !important;
}

/* ===================================
 Width -- percent
====================================== */
.w-0 {
    width: 0;
}

.w-1rem {
    width: 0.063rem !important;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-50 {
    width: 50%;
}

.w-55 {
    width: 55%;
}

.w-57 {
    width: 57%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100%;
}

.w-32vw {
    width: 32vw;
}

.w-auto {
    width: auto;
}

.w-max-content {
    width: max-content !important;
}

.w-fit-content {
    width: fit-content;
}

/* ===================================
     Height--percent
====================================== */
.h-max-content {
    height: max-content !important;
}

.h-70 {
    min-height: 70% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.h-fit-content {
    height: fit-content !important;
}

.min-h-70vh {
    min-height: 70vh;
}

.max-h-100 {
    max-height: 100%;
}

.max-h-84vh {
    max-height: 84vh;
}

.min-h-100vh {
    min-height: 100vh;
}

.max-h-inherit {
    max-height: inherit !important;
}

.max-h-130px {
    max-height: 8.125rem;
}

.h-30vh {
    height: 30vh !important;
}

.h-60vh {
    height: 60vh;
}

.h-70vh {
    height: 70vh;
}

.h-80vh {
    height: 80vh;
}

.h-100vh {
    height: 100vh;
}

/* min height */
.min-h-100vh {
    min-height: 100vh;
}

.min-h-30vh {
    min-height: 30vh !important;
}

.min-h-50vh {
    min-height: 50vh;
}

.min-h-120px {
    min-height: 120px;
}

.min-w-120px {
    min-width: 120px;
}

/* ===================================
     LETTER SPACING
====================================== */

.letter-spacing-007em {
    letter-spacing: 0.007em;
}

.letter-spacing-025px {
    letter-spacing: 0.25px;
}

.letter-spacing-011px {
    letter-spacing: 0.11px;
}

.letter-spacing-010px {
    letter-spacing: 0.10000000149011612px;
}

/* ===================================
     CURSOR
====================================== */

.cursor-pointer {
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    opacity: 0.7;
}
.pointer-events-none {
    pointer-events: none;
}
/* ===================================
     BOX SHADOW
====================================== */

.e-box-shadow-black {
    box-shadow: 8px 16px 56px 0px #0000000a;
}

.e-box-shadow-tooltip {
    box-shadow: 8px 16px 56px 0px #0000001f;
}

.e-box-shadow-chart {
    box-shadow:
        0px 4px 16px 0px #100b2714,
        0px 0px 2px 0px #00000033;
}

.e-box-shadow-white {
    box-shadow: 0px 0px 2px 0px #00000036;
}

.e-box-shadow-black36 {
    box-shadow: 0px 0px 1px 0px #00000036;
}

.rotate-90deg {
    transform: rotate(90deg);
}

// object
