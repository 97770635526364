@import "../color.module.scss";

.e_modal {
    .e_dialog {
        max-width: 40rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;
            border: none;

            .e_notification {
                // background: linear-gradient(99.07deg, #7a31b0 39.08%, #3558b3 100%);
                // width: 174px;
                // top: -122px;
                // bottom: 0;
                // right: 0;
                // margin: auto;
                // height: fit-content;
                // left: 6px;
            }
        }
    }
}
