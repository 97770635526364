@import "../color.module.scss";

.e_modal {
    .e_dialog {
        max-width: 48rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;
            border: none;
            .e_body_wrapper {
                height: 440px;
                overflow-y: scraoll;
                overflow-x: hidden;
                padding-right: 10px;

                &::-webkit-scrollbar {
                    width: 8px !important;
                    height: 0px;
                    display: block !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $scrollbar_color !important;
                }
            }
        }
    }
}

.e_progress_modal {
    .e_dialog {
        max-width: 50rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;
            border: none;
            .e_body_wrapper {
                height: 440px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding-right: 10px;

                &::-webkit-scrollbar {
                    width: 8px !important;
                    height: 0px;
                    display: block !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $scrollbar_color !important;
                }
            }
        }
    }
}
.e_modal_wrapper {
    .e_dialog {
        max-width: 40rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;
            border: none;
        }
    }
}

.e_delete {
    .e_dialog {
        max-width: 30rem;

        .e_content {
            border-radius: 1.75rem;
            background: #fcfbfd;
            box-shadow: 0px 0px 2px 0px #00000036;
        }
    }
}

.e_notification_wrapper {
    .e_dialog {
        max-width: 40rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;

            .e_notification_content {
                background: linear-gradient(100.31deg, #7a31b0 -11.65%, #3558b3 113.58%);
            }
        }
    }
}

.e_change_password {
    .e_dialog {
        max-width: 30rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;

            .e_notification_content {
                background: linear-gradient(100.31deg, #7a31b0 -11.65%, #3558b3 113.58%);
            }
        }
    }
}

.e_notification_modal {
    .e_dialog {
        max-width: 45rem;

        .e_content {
            border-radius: 1.75rem;
            background: $white;

            .e_date_input {
                border: solid 1px $light_gray !important;
                padding: 11px 16px !important;
                // line-height: 20px;
            }

            .e_modal_fields {
                height: 426px;
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 10px;
                &::-webkit-scrollbar {
                    width: 8px !important;
                    height: 0px;
                    display: block !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $scrollbar_color !important;
                }
            }
        }
    }
}
