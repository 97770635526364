/*  File : color.scss
*   Author : https://evoqins.com
*   Description : SCSS file for define and export colors
*   Integrations : NA
*   Version : 1.0.0
*/

// Color declaration
$white: #ffffff;
$black: #000000;
$red: #f6172d;
$rebecca_purple: #662d91;
$charleston_green: #23262f;
$royal_purple: #844cb033;
$ghost_white: #f5f6ff;
$light_lavender: #f2ddff;
$crimpson_red: #c91d30;
$off_white: #effef5;
$dark_charcoal: #141514;
$lavender_gray: #f0eaf4;
$dark_gray: #414441;
$gray: #6d6d6d;
$stroke_gray: #f3f3f3;
$jett_black: #010101;
$white_smoke: #f0f0f0;
$pale_gray: #e3e3e3;
$turqoise_green: #069a6e;
$burnt_orange: #f99f19;
$navy_blue: #2530cb;
$lavender_mist: #f5e7ff;
$pale_lilac: #fbf7fe;
$emerald_green: #17b55d;
$gunmetal_gray: #2d2d2d;
$mint_gray: #e5e9e6;
$pale_lavender: #f9f5ff;
$golden_yellow: #e7b51c;
$old_silver: #828282;
$deep_carmine_pink: #d92d20;
$light_gray: #bdbdbd;
$dark_jungle_green: #101828;
$black_olive: #3d3636;
$magnolia: #f8eeff;
$ethereal_lavender: #fbf5ff;
$lavender_white: #f7f1ff;
$silver_gray: #9d9d9d;
$lavender_white: #f7f1ff;
$thistle: #faf4ff;
$lilac_frost: #eedafe;
$white_lilac: #faf3ff;
$pastel_lavender: #f6edfc;
$snowy_lavender: #fcf8ff;
$scrollbar_color: #ac76d5;
$dark-grayish-blue: #353945;
$light_purple: #f7f0fc;
$pale_purple: #faf6ff;
$medium_gray: #838383;
$magenta: #9a0664;
$bright-periwinkle: #767dff;

:export {
    white: $white;
    black: $black;
    red: $red;
    rebecca_purple: $rebecca_purple;
    lavender_gray: $lavender_gray;
    dark_charcoal: $dark_charcoal;
    dark_gray: $dark_gray;
    pale_gray: $pale_gray;
    charleston_green: $charleston_green;
    light_gray: $light_gray;
    lavender_mist: $lavender_mist;
    scrollbar_color: $scrollbar_color;
    turqoise_green: $turqoise_green;
    burnt_orange: $burnt_orange;
    navy_blue: $navy_blue;
    crimpson_red: $crimpson_red;
    magenta: $magenta;
}
