@import "./color.module.scss";

.e-app {
    overflow-x: hidden;
}

.e-page {
    width: calc(100% - 92px);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.e-file-label:focus-within {
    outline: none !important;
}

.outline:focus {
    outline: none !important;
}

input[type="number"] {
    -moz-appearance: textfield !important;
}

.e-body-wrapper {
    padding: 0 52px 58px 52px;
    margin-top: -4.5rem;
}

.rdt_Pagination span {
    display: none;
}

.form-check-input[type="checkbox"] {
    border-radius: 2px;
    margin-top: 2px;
}

.form-check-input:focus {
    border: 2px solid $gray;
}

.form-check-input:checked[type="checkbox"] {
    --bs-form-check-bg-image: none;
    border-radius: 2px;
    background-size: 8px;
    background-image: url("../Assets/Images/Icons/check.svg");
}

.form-check-input:checked {
    background-color: $rebecca_purple;
    border-color: $rebecca_purple;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-check-input[type="checkbox"]:indeterminate {
    background-color: $rebecca_purple;
    border-color: $rebecca_purple;
    background-size: 8px;
    // --bs-form-check-bg-image: url("../Assets/Images/Icons/check.svg");
}

#pagination-previous-page,
#pagination-first-page,
#pagination-last-page,
#pagination-next-page {
    border-radius: 8px !important;
    background-color: $stroke_gray;
    margin: 0 8px;
}

// login
.e-login {
    background: linear-gradient(121.4deg, #faf5ff 26.12%, #eedffd 73.86%);
    min-height: 88vh;
    .e-wrapper {
        box-shadow:
            0px 0px 2px 0px #00000033,
            0px 4px 16px 0px #100b2714;
        width: 30vw;
    }
}

// toast
.Toastify__toast-body {
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    color: $dark_charcoal;
    padding: 0.375rem 0.75rem 0.375rem 0.375rem !important;
}

.Toastify__toast--success .Toastify__toast-icon svg {
    fill: $rebecca-purple;
    /* Change the fill color to purple or your desired color */
}

.Toastify__progress-bar--success {
    background: $rebecca-purple !important;
}

// date
.e-date-input {
    border: solid 1px $light_gray !important;
    padding: 12px 16px !important;
}

// badge
.e-success-badge {
    background-color: $turqoise_green;
}

.e-processing-badge {
    background-color: $burnt_orange;
}

.e-pending-badge {
    background-color: $navy_blue;
}

.e-failed-badge {
    background-color: $crimpson_red;
}

.e-table div:first-of-type {
    border-radius: 0px;

    &::-webkit-scrollbar {
        width: 0rem;
        height: 0.5rem;
    }
}

.e-tool-tip-container:hover ~ .e-table div:first-of-type {
    overflow: visible;

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar_color;
        border-radius: 0.5rem;
    }
}

.e-order-list-tooltip {
    padding: 16px !important;
    box-shadow: 8px 16px 56px 0px #0000001f !important;
    color: $jett_black !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    border-radius: 16px !important;
    font-family: "Montserrat-Regular" !important;
    background: $white !important;
    opacity: 1 !important;
    z-index: 99 !important;
    position: absolute;
    // left: -44px;
}

.e-order-list-tooltip .react-tooltip-arrow {
    display: none !important;
}

.translate-x-40 {
    transform: translateX(-48%);
}

.e-risk-container .e-guage-component svg {
    position: absolute;
    top: -24px;
}

.e-risk-modal .e-guage-component svg {
    position: absolute;
    top: 0px;
}

.e-portfolio-list-tooltip {
    opacity: 1 !important;
    background-color: white !important;
    border-radius: 0.5rem !important;
    max-width: 224px !important;
    text-align: center;
}

.rdp-day_selected {
    background: $rebecca_purple !important;
}

.rdp-day_selected:hover {
    background: $rebecca_purple !important;
    color: $white !important;
}

.rdp-day_range_middle {
    background-color: $lavender_gray !important;
    color: $dark_charcoal !important;
}

.rdp-month {
    background-color: $white !important;
    border-radius: 4px !important;
    padding: 10px !important;
    box-shadow: 8px 16px 56px 0px #0000000a;
}

.rdp {
    position: relative;
    left: -16px;
    font-family: "Montserrat-Regular" !important;
}

.rdp-day:hover,
.rdp-nav button:hover {
    background-color: $lavender_gray;
}

.e-redirection-input::placeholder {
    text-transform: lowercase !important;
}

.e-customize-btn {
    border-radius: 150px;
    background-color: $white;
    border: 1px solid transparent;
    box-shadow:
        0px 4px 16px 0px #100b2714,
        0px 0px 2px 0px #00000033;
}

.e-customize-btn:hover {
    border: 1px solid $rebecca_purple;
    cursor: pointer;
}

// time picker
.e-popup-time-picker {
    .rc-time-picker-panel-inner {
        width: 362px;
        border-radius: 8px;
    }

    .rc-time-picker-panel-input {
        &::placeholder {
            font-family: "Montserrat-Regular" !important;
            color: $dark_charcoal !important;
        }
    }

    .rc-time-picker-panel-input-wrap {
        padding: 10px 16px;
        border-radius: 8px 8px 0 0;
        font-size: 16px;
        font-family: "Montserrat-medium";
        color: $charleston_green;
        line-height: 18px;
        height: unset;
        &::placeholder {
            font-family: "Montserrat-Regular" !important;
            color: $dark_charcoal !important;
        }

        &:focus-within {
            outline: none;
        }
    }

    .rc-time-picker-panel-inner {
        margin-top: 2.5px;
    }

    .rc-time-picker-panel-input-invalid {
        border-color: transparent;
    }

    .rc-time-picker-panel-combobox {
        .rc-time-picker-panel-select {
            &::-webkit-scrollbar {
                width: 4px !important;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $scrollbar_color !important; // Assuming $light_lavender is a SCSS variable
                border-radius: 0.5rem !important;
            }
        }
    }
}

.e-pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    color: $medium_gray;
    text-align: center;
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
}

.e-pagination-active > a {
    padding: 0.625rem 1.125rem;
    background-color: $light-lavender;
    border-radius: 0.5rem;
    font-family: "Montserrat-SemiBold";
    font-weight: 600;
    color: $rebecca-purple !important;
}

.bounce {
    position: relative;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Adjust bounce height */
    }
}

.show-scrollbar {
    &::-webkit-scrollbar {
        width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar_color !important; // Assuming $light_lavender is a SCSS variable
        border-radius: 0.5rem !important;
    }
}

.e-file-upload {
    width: 100%;
}

.e-delete-hover:hover {
    fill: $red;
}

// faq
.e-faq-items {
    border-radius: 12px !important;

    button {
        box-shadow: none !important;
        color: $dark_charcoal !important;

        &::after {
            background-image: url("../Assets/Images/faq/accordian-arrow.png") !important;
        }
    }
}

// calender
.e-calender-backdrop {
    opacity: 0.5;
}

.e-calender {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.e-app-zoom-level-9 {
    zoom: 0.9 !important;

    .max-h-zoom-100vh {
        max-height: 100vh !important;
    }
}

.e-app-zoom-level-8 {
    zoom: 0.8 !important;

    .max-h-zoom-100vh {
        max-height: 100vh !important;
    }
}

.e-app-zoom-level-7 {
    zoom: 0.6 !important;
}

.show {
    width: 100%;
    height: 100%;
}

#close-modal {
    transition: 0.5s all ease-in-out;

    &:hover {
        transform: scale(1.2);
        transition: 0.5s all ease-in-out;
    }
}

// summary card tooltip
.e-summary-hover {
    border-radius: 0.375rem !important;
    background: $rebecca_purple !important;
    box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(175, 175, 175, 0.58);
    opacity: 1 !important;
}
