@import  "../color.module.scss";

.e_download_btn {
    display: inline-flex;
    padding: 0.5rem 1rem;
    background-color: $rebecca_purple;
    border: solid 1px $rebecca_purple;
    color: $white;
    font-family: "Montserrat-Medium";
}
.e_disabled_btn {
    display: inline-flex;
    pointer-events: none;
    background-color: $stroke_gray;
    color: $gray;
    border: solid 1px $stroke_gray;
    padding: 0.5rem 1rem;
    font-family: "Montserrat-Medium";
}
