@import  "../color.module.scss";
.e_text_button {
    display: flex;
    align-items: center;
}

.e_text_button:hover {
    text-decoration: underline;
}

.e_text_button .e_arrow {
    transition: 0.3s all ease-in-out;
    transform: translateX(0px);
}

.e_text_button:hover .e_arrow {
    transition: 0.3s all ease-in-out;
    transform: translateX(5px);
}
