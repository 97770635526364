@import "../color.module.scss";

.e_file {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23BDBDBD' stroke-width='3' stroke-dasharray='1, 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
    height: 114px;
    // height: 120px;
}

.e_preview {
    background-color: #faf4ffcc;
    border-radius: 16px;
    padding: 8px;
    margin: 4px !important;
}